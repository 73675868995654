import React, { useState, useContext } from "react";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { useParams, Link, NavLink, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import FnbContext from "../contextApi/FnbContext";
import { getUser, removeUserSession } from "../utils/UserAuthenticate";

const Footer = () => {
  const {
    cartItems,
    subTotalfunction,
    handleChangeIncDec,
    removeItem,
    subTotal,
  } = useContext(FnbContext);
  const navigate = useNavigate();
  const [smShow, setSmShow] = useState(false);
  const emptyCartImage = process.env.PUBLIC_URL + "images/ShopCart.png";

  const [emptyCartShow, setEmptyCartShow] = useState(false);
const user=getUser()
const handleLogout = () => {
  setSmShow(false)
  removeUserSession()
 navigate('/')
};
  return (
    <>
      {/* footer for phone view*/}
      <div className="row ph-footer ds-none">
        <div className="col-1"></div>
        <div className="col">
          <div className="c1">
            <Link to="/" style={{textDecoration:"none", color:"black"}} className="link">
              <HomeOutlinedIcon />
            </Link>
          </div>
        </div>

        <div className="col ">
          {/* <div className="c1" onClick={handleNavigatePhone}> */}
          {/* <a href={"tel:++91022-48931314"} target="_blank"  style={{textDecoration:"none", color:"black"}}> */}
          {/* <WhatsAppIcon style={{textDecoration:"none", color:"black"}}/> */}
          {/* </a> */}
          {/* </div> */}

          <div className="c1">
            <Link to={`https://wa.me/+919653188646`} target="_blank">
              <WhatsAppIcon
                style={{ textDecoration: "none", color: "black" }}
              />
            </Link>
          </div>
        </div>
        <div className="col ">
          <div class="c1 c2">
            {cartItems.length !== 0 ? (
              <>
                <NavLink to="/cart">
                  <ShoppingCartOutlinedIcon
                    style={{ fontSize: 20 }}
                    onClick={subTotalfunction}
                  />
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      margin: 0,
                      color: "#a2c760",
                    }}
                  >
                    {" "}
                    {cartItems
                      ? cartItems.length === 0
                        ? ""
                        : cartItems.length
                      : ""}{" "}
                  </p>
                </NavLink>
              </>
            ) : (
              <ShoppingCartOutlinedIcon
                style={{ fontSize: 20 }}
                onClick={() => setEmptyCartShow(true)}
              />
            )}
          </div>
        </div>
        <div className="col text-end">
          <div className="c1">
            <MoreVertOutlinedIcon onClick={() => setSmShow(true)} />
          </div>
        </div>
      </div>

      <Modal
        size="sm"
        style={{ marginTop: "10%" }}
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="menu-footer-mob">
            <ul>
              <li>
                <Link to="/my-events">Organizer Dashboard</Link>
              </li>
              <Link to="/your-order-history">
                <li>Order & Shipping</li>
              </Link>
              <Link to="/events">
                <li>FAQ Events</li>
              </Link>
              <li>
                <Link to="/food">Food</Link>
              </li>
              <li>
                <Link to="/beer">Beer</Link>
              </li>
              <li>
                {" "}
                <Link to="/merchandise">Merchandise</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/mug-club">Mug Club</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
{user? <li onClick={handleLogout}>
 <Link> {user.firstName+user.lastName}{" "}(Logout)</Link> 
</li> :
              <li>
                <Link to="/login">Login</Link>
              </li>
              }
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/* Cart Empty Alter */}
      <Modal
        size="sm"
        style={{ marginTop: "10%" }}
        show={emptyCartShow}
        onHide={() => setEmptyCartShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Your cart is empty!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="menu-footer-mob" style={{ textAlign: "center" }}>
            <img src={emptyCartImage} />
            <h1>Cart Empty</h1>
            <Link
              className="btn btn-success"
              style={{ background: "rgb(150 179 103)" }}
              to="/beer"
              onClick={() => setEmptyCartShow(false)}
            >
              View Menu
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Footer;
