import React, { memo, useEffect, useMemo, useState } from "react";
import "../style_web/styleDoolally.css";
import { Link } from "react-router-dom";
import Review from "../Sliders/Review";
import Header from "../components/HeaderWeb";
import Footer from "../components/FooterWeb";
import Helper from "../utils/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import GallerySliderLocation from "../Sliders/GallerySliderLocationWeb";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import { format, isValid } from "date-fns";
import {
  getRequestOptions,
  apiBaseUrl,
  homePageApiUrl,
  fBDImageBaseUrl,
} from "../config/constant";
import {
  setUserCurrLoc,
  getUserCurrLoc,
} from "../utils/UserAuthenticate";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { showCorrectImage, numberWithCommas } from "../utils/Common";
const whatsOnTapImages=[{
  filename:'Alphonso Mead.png',
  itemName:'Alphonso Mead',
},
{
  filename:'Apple Cider.png',
  itemName:'Apple Cider',
} ,
{
  filename:'Belgian Witbier.png',
  itemName:'Belgian Witbier',
} ,
{
  filename:'Blueberry Cider.jpg',
  itemName:'Blueberry Cider',
} ,
{
  filename:'Coffee Orange Mead.jpg',
  itemName:'Coffee Orange Mead',
} ,
{
  filename:'English Brown Ale.png',
  itemName:'English Brown Ale',
} ,
{
  filename:'Hefeweizen.png',
  itemName:'Hefeweizen',
} ,
{
  filename:'Rauchbier.png',
  itemName:'Rauchbier',
} ,
{
  filename:'7.png',
  itemName:'Oatmeal Stout',
} 

]

const Location = () => {
  const [whatsOnTap, setwhatsOnTap] = useState([]);
  const [whatHappingWeek, setwhatHappingWeek] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [LocationData,setLocationData] = useState([]);
  const [selectedLocation,setSelectedLocation] = useState('Mumbai');
// -----------------------------------------------------------api calls--------------------------------------------------------
const getLocationPageData = async (desiredState) => {
  try {
    const response = await axios.get(
      `${apiBaseUrl}common/get-location-page-data`,
      {
        params: {
          state: desiredState,
        },
      }
    );
      const filteredData = response.data.response.result.filter(item => item.state === desiredState);
      setLocationData(filteredData )
      setSelectedLocation(desiredState);
  } catch (error) {
    // Handle errors here
    console.error('Error:', error);
  }
};
useEffect(()=>{
   
  // Call the function to fetch data
  getLocationPageData('Mumbai');
  
},[]);





// -----------------------------------------------------------end api calls--------------------------------------------------------

const getData = async () => {
  try {
    setIsloading(true);
    // here Api call for Home page
    let result = await fetch(apiBaseUrl + homePageApiUrl, getRequestOptions);
    if (result) {
      result = await result.json();
      setwhatsOnTap(result.response.result.whatsOnTap);
      setwhatHappingWeek(result.response.result.whatHappingWeek);
      setTimeout(() => {
        setIsloading(false);
      }, 500);
    }
  } catch (error) {
    console.log("error", error);
  }
};

  useEffect(() => {
    Helper.checkCurrentUserLegalAge().then((res) => {
      if (res.legalAge === "No") {
        window.location = "/homepage";
      }
    });
    if (!getUserCurrLoc()) {
      setUserCurrLoc();
    }
    getData();
    //removeUserSession();
  }, []);



  return (
    <>
      <div className="container-fluid">
        <Header />
        <section className="d_main_panel" style={{ margin: "0 1%" }}>
          <div className="container-fluid">
            <div className="row g-5">
            <div className="col mb-4" >
                <div className="d_left_pana box_padding gray_bg_color" style={{height:"940px",overflow:"scroll"}} >
                  <h5 className="d_main_title">What's On Tap </h5>
                  <p className="d_main_sub_title">
                  {/* Citrusy Witbier, Dark Decadent Stouts, Fruity Cider */}
                  Order craft brews online, home delivery across Mumbai & Pune within 2 hours.
                    <span className="d_line"></span>
                    <span className="d_round"></span>
                    <span className="d_round"></span>
                    <span className="d_round"></span>
                  </p>
                  <div className="scrollbar" style={{overflow:"scroll"}}>
                  <div className="row gy-2 gx-4" >
                    {/* here the Api render on home page for whats on tap */}
                    {whatsOnTapImages.map((item, index) => (
                      <div key={index} className="col-md-6 " id="whatsonTap">
                      <Link to='/beer'>                      <div className="d_product_box">
                          <figure className="d_product_img mb-0" key={index}>
                            <img
                              src={process.env.PUBLIC_URL+'./WoodcutBeersLatest/'+item.filename}
                              // src={fBDImageBaseUrl + "thumb/" + item.filename}
                              alt=""
                            />
                          </figure>
                          <p className="d_product_title">{item.itemName}</p>
                          <a className="d_overlay">
                            <p>Explore More</p>
                          </a>
                        </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4 ">
                <div className=" d_midile_panal gray_bg_color">
                  {/* <Location/> */}
                  {/* <h3 className="d_main_title box_padding">Our Locations</h3> */}
                  <div className="location-section ">
                    <div className="d-flex justify-content-center pt-3">
                      <div className="adj-location" onClick={()=>getLocationPageData('Mumbai')} id={selectedLocation==='Mumbai'?'circle-effect':''}>
                        <Link >
                          {" "}
                          <img
                            src={process.env.PUBLIC_URL + "/images/mumbai.png"}
                            style={{ width: "60%" }}
                          />
                          <h5> Mumbai</h5>
                        </Link>
                      </div>
                      <div className="adj-location" onClick={()=>getLocationPageData('Pune')} id={selectedLocation==='Pune'?'circle-effect':''}>
                        <img
                          src={process.env.PUBLIC_URL + "/images/pune.png"}
                          style={{ width: "60%" }}
                        />
                        <h5> Pune </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="box_padding gray_bg_color"
                  style={{overflow:"scroll",height:"670px"}}
                >
                  <div className="d_title_box">
                    <div className="d_hangout_box">
                    {LocationData.map((location, index) => (
  <div
    key={index}
    className="d-flex justify-content-between"
    style={{
      border: "1px solid #d4d4d4",
      borderRadius: "5px",
      boxShadow: "2px 5px 5px 5px #f5f5f5",
      marginBottom: "2%",
      padding: "2% 2% 0 2%",
    }}
  >
    <div>
      <h5 className="poppins">{location.taproom}</h5>
      <img
        src={process.env.PUBLIC_URL + "/images/icons/star.jpg"}
        style={{
          width: "60px",
          margin: "0px auto",
          marginTop: "-20px",
        }}
      />
      <p
        style={{
          fontSize: "14px",
          color: "grey",
          width: "280px",
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: location.address }}></span>
        <br />
        <FontAwesomeIcon
          icon={faPhone}
          beat
          style={{ color: "#88ad00", margin: "0px" }}
        />
        <a href={`tel:+91${location.phone}`} style={{ margin: "5px" }}>
          +91 {location.phone}
        </a>
      </p>
    </div>

    <div className="col text-end">
      <div style={{ position: "relative", display: "inline-block" }}>
        <a href={location.mapLink} target="_blank">
          <img
            src={process.env.PUBLIC_URL + "/images/map.png"}
            style={{ width: "120px", height: "125px" }}
          />
          <FontAwesomeIcon
            icon={faLocationDot}
            style={{
              position: "absolute",
              top: "10px", // Adjust as needed for vertical alignment
              right: "10px", // Adjust as needed for horizontal alignment
              color: "#9BC059",
              fontSize: "30px",
            }}
          />
        </a>
      </div>
      <p style={{ fontSize: "14px", color: "grey", paddingTop: "2%" }}>
        {location.timing}
      </p>
    </div>
  </div>
))}

               
           
            
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                {/* <div className=" d_main_panel_review "> */}
                <div className="d_right_panal box_padding">
                  <div className="d_title_box">
                    <h5 className="d_main_title mb-3">Gallery</h5>

                    <GallerySliderLocation />
                    <h5 className="d_main_title mt-4 pt-4">What others have to say</h5>
                    <p className="d_main_sub_title">
                      What Our Happy Client Says
                      {/* <span className="d_line"></span>
                      <span className="d_round"></span> */}
                    </p>

                    <Review />
                  </div>
                </div>
                <div className=" d_main_panel_review ">
                  <div
                    className="d_right_panal box_padding whatHappingWeek sh-2"
                  
                  >
                    <div className="d_title_box">
                      <h5 className="d_main_title">
                        What's happening this week
                      </h5>
                      <p className="d_main_sub_title">
                        Day Plan <span className="d_line"></span>
                        <span className="d_round"></span>
                        <span className="d_round"></span>
                        <span className="d_round"></span>
                      </p>
                    </div>
                    {whatHappingWeek.map((item, id) => (
                      <div className="d_days_box mb-4">
                        {item.eventDate && (
                          <h3 className="d_days_title">
                            {format(new Date(item.eventDate), "iiii")}
                          </h3>
                        )}
                        <div className="d_days_iner_box mb-3">
                          <div>
                            <figure className="mb-0">
                              <Link
                                to={
                                  "/event-details/" +
                                  item.eventSlugs +
                                  "__" +
                                  item.eventIds
                                }
                              >
                                <img
                                  src={showCorrectImage(
                                    item.eventImage,
                                    "w_80"
                                  )}
                                  alt=""
                                />
                              </Link>
                            </figure>
                          </div>
                          <Link
                            to={
                              "/event-details/" +
                              item.eventSlugs +
                              "__" +
                              item.eventIds
                            }
                          >
                            <p className="d_days_subtitle ">
                              {item.eventNames}
                            </p>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
        <Footer />
      </div>

      {/* modal opens for pune  */}
      <Modal show={show} onHide={handleClose}   centered>
        <Modal.Header closeButton>
<Modal.Title>Pune Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>Soon coming to your neighbourhood Adda!!!!</Modal.Body>
    
      </Modal>
      {/* end popup open pune */}
    </>
  );
};
export default Location;
